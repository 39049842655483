import React from "react"

import socialImage from "../../static/DECAZES-social-share.jpg"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import Liens from "../components/Liens"

const LiensPage = () => {
  return (
    <Layout>
      <SEO
        title="Liens"
        description="Retrouvez ici tous les liens relatifs aux membres de la famille Decazes"
        image={socialImage}
      />
      <Liens />
    </Layout>
  )
}

export default LiensPage
