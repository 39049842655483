import React from "react"
import { useSpring, animated } from "react-spring"

import "./styles.scss"

const Liens = () => {
  const textprops = useSpring({
    to: { opacity: 1, transform: "translateY(0)" },
    from: { opacity: 0, transform: "translateY(5%)" },
    config: { mass: 1, tension: 240, friction: 38, precision: 0.00001 },
    delay: 100,
  })
  return (
    <div className="liens">
      <animated.div style={textprops} className="liens-text">
        <h1>Liens</h1>
        <h2>
          Vous trouverez sur cette page une liste de liens relatifs aux
          activités des membres de la famille Decazes.
        </h2>
        <article className="liens-text-article">
          <h3>Marisol Ceramics</h3>
          <p>
            Marisol Jacquemot Derode is a French artist who has been living in
            London for the last ten years. She has worked in various areas
            including set designing in the French film industry and interior
            designing of private properties in Washington DC and France. Her
            interests also lie in painting, architecture, landscaping and now
            ceramics. Her techniques have constantly evolved since she started
            ceramics and her latest creations are shown on this website. These
            are all handmade and unique pieces. She has recently started to work
            with the potter’s wheel and some of her smaller scale works made
            using this technique are also shown here. She is happily married and
            the proud mother of two beautiful children.
          </p>
          <a
            href="https://www.marisolceramic.com"
            target="_blank"
            rel="noopener noreferrer"
            className="liens-text-article-btn"
          >
            www.marisolceramic.com
          </a>
        </article>
        <article className="liens-text-article">
          <h3>Château de Breteuil</h3>
          <p>
            Bienvenue au château de Breteuil situé au coeur de la vallée de
            Chevreuse près de Paris. Parc, jardin, château se visitent tous les
            jours.
          </p>
          <a
            href="http://www.breteuil.fr"
            target="_blank"
            rel="noopener noreferrer"
            className="liens-text-article-btn"
          >
            www.breteuil.fr
          </a>
        </article>
        <article className="liens-text-article">
          <h3>Roger de Montebello</h3>
          <p>
            Site officiel de Roger de Montebello, peintre et artiste
            contemporain.
          </p>
          <a
            href="http://www.montebellopaintings.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="liens-text-article-btn"
          >
            www.montebellopaintings.com
          </a>
        </article>
        <article className="liens-text-article">
          <h3>Plantco France</h3>
          <p>
            Plantco France est une entreprise de produits/services crée par
            Guillaume Decazes dont le principe d’action repose sur l’innovation
            au service de la performance de ses clients nationaux et
            internationaux. Crée en 1996, Plantco France développe des
            techniques et des produits d’ancrage, d’entretien et de protection
            des sols dans près de 20 domaines d’activités. Le métier
            de Plantco France est de proposer aux professionnels de
            l’environnement des solutions d’ingénierie basées sur les services
            et les ressources humaines.
          </p>
          <a
            href="https://www2.plantco.fr/"
            target="_blank"
            rel="noopener noreferrer"
            className="liens-text-article-btn"
          >
            www2.plantco.fr
          </a>
        </article>
      </animated.div>
    </div>
  )
}
export default Liens
